import React, { useState, useEffect } from 'react'
import '../PopupGame/popUpGame.scss'
import iconX1 from '../../images/home/iconX1.svg'
import popUp from '../../images/home/popup.png'

const VideoHome = ({ open, setOpen }) => {
  const [delayedOpen, setDelayedOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => setDelayedOpen(true), 500)
      setTimeout(() => setIsVisible(true), 550)
    } else {
      setIsVisible(false)
      setTimeout(() => setDelayedOpen(false), 500)
    }
  }, [open])

  if (!delayedOpen) return null

  return (
    <div className={`floating-card ${isVisible ? 'show' : 'hide'}`}>
      <div className="card-content">
        <div className="closeModal">
          <a
            style={{
              textAlign: 'right',
              cursor: 'pointer'
            }}
            className="close-button"
            onClick={() => setOpen(false)}
          >
            <img
              src={iconX1}
              alt=""
              className="mb-lg-0 mb-2"
              style={{
                width: '15px',
                height: '15px'
              }}
            />
          </a>
        </div>
        <a href="/blog/">
          <img src={popUp} alt="PopUp do Blog" className="card-image" />
        </a>
      </div>
    </div>
  )
}

export default VideoHome
